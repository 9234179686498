<template>
  <div>
    <wapHeader />
    <router-view />
    <wapFooter />
  </div>
</template>

<script>
import './assets/js/wap.js'
import wapHeader from './components/wapHeader.vue'
import wapFooter from './components/wapFooter.vue'

export default {
  name: '',
  components: {
    wapHeader,
    wapFooter
  }
}
</script>

<style lang="scss">

</style>
