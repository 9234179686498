<template>
  <div class="nav-header">
    <div class="wraper">
      我是wap头部的区域
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgChang: Boolean
  },
  data() {
    return {
      company: false,
      product: false,
      news: false,
      increment: false
    }
  },
  mounted() {
    // 绑定自定义事件
  },
  methods: {
    // 返回首页
    backIndex() {
      this.$router.push('/index')
    },
    changNavStyle(slideIndex) {
      if (slideIndex.slideInEnd === 2 && slideIndex.slideInUp === 1) {
        this.navStyleChang.menuIndex = true
      } else {
        this.navStyleChang.menuIndex = false
      }
    }
  }
}
</script>

<style lang="scss">

</style>
