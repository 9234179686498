<template>
  <div>
    我是wap底部的区域
  </div>
</template>

<script>

export default {
  name: 'Join',
  components: {
  },
  data() {
    return {
      list: [],
      number: 0
    }
  },
  created() {
  },
  methods: {
    toFormSunmit() {
      this.$nextTick(() => {
        this.$refs.contractForm.init()
      })
    }

  }

}
</script>

<style lang="scss">

</style>
